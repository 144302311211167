import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getTransactions (params) {
  return httpClient.get('/api/transaction', { params })
}

export function createTransaction (payload) {
  return httpClient.post('/api/transaction', payload)
}

export function getTransactionApprovalDetails (transactionId, approvalId) {
  return httpClient.get(`/api/transactions/${transactionId}/approvals/${approvalId}`)
}

export function updateTransactionApprovalDetails (transactionId, approvalId, payload) {
  return httpClient.patch(`/api/transactions/${transactionId}/approvals/${approvalId}`, payload)
}

export function createTransactionBooking (transactionId) {
  return httpClient.post(`/api/transactions/${transactionId}/book`)
}

export function updateTransaction (transactionId, payload) {
  return httpClient.patch(`/api/transactions/${transactionId}`, payload)
}

export function finishSuspensiveCondition (transactionId) {
  return httpClient.post(`/api/transactions/${transactionId}/finish-suspensive-condition`)
}

export function getThirdPartyAccounts (payload = {}) {
  const { url = '/api/third-party-accounts', params } = payload
  return httpClient.get(url, { params, paramsSerializer: commaSerializer })
}

export function getAdvances (payload = {}) {
  const { url = '/api/advances', params } = payload
  return httpClient.get(url, { params, paramsSerializer: commaSerializer })
}

export function updateAdvance (advanceId, payload) {
  return httpClient.patch(`/api/advances/${advanceId}`, payload)
}

export function createInvolvement (payload) {
  return httpClient.post('/api/involvements', payload)
}

export function calculateTransactionInvolvementData (payload) {
  return httpClient.post('/api/transaction-involvement-data', payload)
}

export function createTransactionOfficesAndPoules (payload) {
  return httpClient.post('/api/transaction-offices-and-poules', payload)
}
