<template>
  <FormulateForm
    #default="{ isLoading }"
    name="wizardDossierOpbouwStep2"
    v-model="values"
    @submit="submit"
  >
    <div v-if="shouldCreateTransaction">
      <TransactionIntakeInputs
        ref="transactionIntakeInputs"
        :property="property"
      />
      <h2 class="tw-mt-8 tw-mb-4">Betrokken kantoren en poules</h2>
      <TransactionInvolvementData
        ref="transactionInvolvements"
        context="dossierPreparation"
        :property-id="property.id"
      />
    </div>
    <div v-else>
      <h2 class="tw-mb-4">Mandaat</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="date"
          name="mandate_signature_date"
          label="Datum ondertekening opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          name="mandate_start_date"
          label="Startdatum opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          name="mandate_end_date"
          label="Einddatum opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|optional|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="file"
          name="mandate_file"
          label="Opdrachtdocument"
          :uploader="uploadFile"
          outer-class="formulate-input tw-my-0"
          wrapper-class="formulate-input-wrapper"
          :upload-area-mask-class="['tw-h-10 tw-mt-1']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
        >
          <template #file="context">
            <BaseFileUploadDisplay v-bind="context" />
          </template>
        </FormulateInput>
      </div>
    </div>
    <FormulateErrors class="tw-text-right" />
    <div class="tw-flex tw-flex-wrap tw-w-full tw-justify-between tw-items-start tw-mt-8">
      <ul class="formulate-help">
        <p class="tw-text-sm tw-font-semibold tw-mb-2">
          Volgende acties zullen worden uitgevoerd na opslaan:
        </p>
        <li v-if="leadToSetAsDeal">
          • Volgende intakelead wordt als deal aangeduid:
          <router-link :to="{ name: 'LeadDetails',  params: { id: leadToSetAsDeal.id } }" target="_blank">
            {{ leadToSetAsDeal.candidate.display_name }}
          </router-link>
        </li>
        <li>• Status van het pand wordt <b>Dossieropbouw</b></li>
        <li>• Checklist <b>Opstart verkoop/verhuur/overname</b> wordt geladen</li>
        <li v-if="shouldCreateTransaction">• Een transactie <b>binnen</b> wordt gecreëerd</li>
        <li v-if="shouldCreateTransaction">• De transactie-unit wordt bepaald uit bovenstaande parameters</li>
        <li v-show="property.allow_automated_communication && !isDiy">• De eigenaars worden <b>per e-mail</b> op de hoogte gebracht van de start van het dossier</li>
      </ul>
      <div class="tw-text-right">
        <FormulateInput
          type="submit"
          outer-class="tw-my-0"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading
                ? 'fa-spinner-third fa-spin'
                : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import TransactionIntakeInputs from '@/components/transactions/TransactionIntakeInputs'
import TransactionInvolvementData from '@/components/transactions/TransactionInvolvementData'

import { createPropertyFile, updateProperty, uploadFile } from '@/services/properties'

import { errorModal } from '@/modalMessages'

const FILE_TYPES = {
  2: 53, // If transaction_type is 'Te koop'
  3: 52, // If transaction_type is 'Te huur'
  4: 76 // If transaction_type is 'Over te nemen'
}

export default {
  name: 'WizardDossierOpbouwStep2',
  components: { TransactionIntakeInputs, TransactionInvolvementData },
  props: {
    property: {
      type: Object
    },
    shouldCreateTransaction: {
      type: Boolean,
      required: false
    },
    leadToSetAsDeal: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      values: {}
    }
  },
  constants: {
    CURRENT_STEP: 2
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    isDiy () {
      return parseInt(this.property.concept_type) === 6
    }
  },
  methods: {
    async submit (data) {
      let responseInputs = null
      let responseInvolvements = null
      let responseProperty = null
      if (this.shouldCreateTransaction) {
        responseInputs = await this.$refs.transactionIntakeInputs.triggerSubmission()
        responseInvolvements = await this.$refs.transactionInvolvements.triggerSubmission()
      } else {
        responseProperty = await updateProperty(this.propertyId, data)
      }
      this.$emit('finished', data, this.CURRENT_STEP)
      return [responseInputs, responseInvolvements, responseProperty]
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folders = await this.createDefaultFolderStructure()
        // Look for a folder called "Verkoop- verhuuropdracht"
        const folder_id = folders?.find(folder => folder.name === 'Verkoop- verhuuropdracht')?.id
        const payload = { key, filename, file_type: FILE_TYPES[this.property.transaction_type], folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        return this.parseFileObj(response.data)
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het uploaden het bestand')
      }
    }
  }
}
</script>
