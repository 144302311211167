<template>
  <span
    v-if="!editing"
    :class="[
      'tw-inline-flex tw-items-center tw-font-semibold tw-px-2 tw-py-1 tw-rounded tw-text-white',
      editable ? 'tw-bg-success' : 'tw-bg-gray-500'
    ]"
  >
    <button
      v-if="editable"
      type="button"
      @click="editing = true"
    >
      Status: {{ statusLowerCase }} {{ isArchived ? '(gearchiveerd)' : '' }}<i class="tw-ml-1 tw-font-sm fa fa-pencil" />
    </button>
    <span v-else>Status: {{ statusLowerCase }} {{ isArchived ? '(gearchiveerd)' : '' }}</span>
  </span>
  <FormulateInput
    v-else
    type="select"
    name="status"
    placeholder="Transactietype"
    v-model="newStatus"
    :options="statusesToRender"
    :input-class="['tw-text-sm tw-h-6 tw-mt-0.5']"
    outer-class="tw-inline-block tw-w-1/8"
    @change="updatePropertyStatus"
  />
</template>

<script>
import { mapState } from 'vuex'
import { updateProperty } from '@/services/properties'
import { PROPERTY_STATUSES } from '@/forms/selectOptions'
export default {
  name: 'PropertyStatus',
  constants: {
    PROPERTY_STATUSES
  },
  props: {
    statusDisplay: {
      type: String,
      required: true
    },
    status: {
      type: Number,
      required: true
    },
    transactionType: {
      type: Number,
      required: false
    },
    propertyId: {
      type: Number,
      required: true
    },
    projectId: {
      type: Number,
      required: false
    },
    isArchived: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      newStatus: this.status,
      editing: false
    }
  },
  computed: {
    ...mapState('account', ['user']),
    isSuperUser () {
      return this.user.is_superuser
    },
    isProspect () {
      return this.status === 1
    },
    isSoldRentedOrAcquired () {
      return [10, 11, 12].includes(this.status)
    },
    isRentalManagement () {
      return this.status === 5
    },
    isRemovedFromPortfolio () {
      return this.status === 15
    },
    editable () {
      if (this.projectId) return true
      if (this.isSuperUser && this.isRemovedFromPortfolio) return true
      if (this.isProspect || this.isRemovedFromPortfolio || (!this.isSuperUser && this.isSoldRentedOrAcquired)) return false
      return true // E.g. property for sale, for rent ...
    },
    statusLowerCase () {
      return this.statusDisplay.toLowerCase()
    },
    statusesToRender () {
      if ((this.isSuperUser || this.projectId) && !this.isRemovedFromPortfolio) return this.PROPERTY_STATUSES
      let statusToUse = this.status
      if (this.isRemovedFromPortfolio) {
        statusToUse = this.transactionType
      }
      switch (statusToUse) {
        case 2:
          return [
            {
              label: 'Te koop',
              value: 2
            },
            {
              label: 'Uit portefeuille',
              value: 15
            }
          ]
        case 3:
          return [
            {
              label: 'Te huur',
              value: 3
            },
            {
              label: 'Uit portefeuille',
              value: 15
            }
          ]
        case 4:
          return [
            {
              label: 'Over te nemen',
              value: 4
            },
            {
              label: 'Uit portefeuille',
              value: 15
            }
          ]
        case 5:
          return [
            {
              label: 'Beheer',
              value: 5
            },
            {
              label: 'Uit portefeuille',
              value: 15
            }
          ]
        case 6:
          return [
            {
              label: 'Syndic',
              value: 6
            },
            {
              label: 'Uit portefeuille',
              value: 15
            }
          ]
        case 16: {
          return [
            {
              label: 'Dossieropbouw',
              value: 16
            },
            {
              label: 'Uit portefeuille',
              value: 15
            }
          ]
        }
        default:
          return []
      }
    }
  },
  methods: {
    async updatePropertyStatus () {
      if (parseInt(this.newStatus) === 15) {
        this.editing = false
        this.$emit('status-changed', this.newStatus)
        return
      }
      const response = await updateProperty(this.propertyId, { status: this.newStatus })
      this.editing = false
      this.$emit('status-changed', this.newStatus)
      return response
    }
  }
}
</script>
