var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"name":"salesAgreementStep3","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-mb-8 tw-pb-4 tw-border-b"},[_c('FormulateInput',{attrs:{"type":"radio","name":"status","label":"Pandstatus","validation":"required","disabled":_vm.isDossierPreparation,"options":_vm.statusOptions,"input-class":['tw-my-1'],"outer-class":"['tw-my-0']","element-class":['tw-flex tw-flex-row tw-gap-4 tw-items-center']}}),(_vm.isDossierPreparation)?_c('div',[_vm._v(" Een pand in de dossieropbouwfase wordt automatisch op '"+_vm._s(_vm.soldOrAcquired)+"' gezet bij het opslaan van dit formulier. ")]):_vm._e(),(parseInt(_vm.values.status) === 2)?_c('FormulateInput',{attrs:{"type":"radio","name":"sticker_id","label":"Sticker","value":"","options":[
        { value: '', label: 'Geen' },
        { value: 10, label: 'Waarschijnlijk verkocht' }
      ],"input-class":['tw-my-1'],"element-class":['tw-flex tw-flex-row tw-gap-4 tw-items-center']}}):_vm._e()],1),_c('h2',[_vm._v("Openstaande leads")]),_c('WizardLeadCount',{attrs:{"property-id":_vm.propertyId}}),_c('FormulateErrors',{staticClass:"tw-my-4 tw-text-right"}),_c('div',{staticClass:"tw-mt-8 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-gray-500'],"outer-class":"tw-my-0"},on:{"click":_vm.goBackToStep2}},[_c('i',{staticClass:"fas tw-mr-2 fa-arrow-left"}),_vm._v(" Vorige stap ")]),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-my-0"}},[_c('i',{class:[
          'fas tw-mr-2',
          isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
        ]}),_vm._v(" Volgende stap ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})}
var staticRenderFns = []

export { render, staticRenderFns }