<template>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      :key="formKey"
      name="dossieropbouwForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-mb-8">
        <h2>Dossier</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="text"
            name="reference"
            label="Referentie"
            placeholder="Referentie"
            :validation="[
              ['bail'],
              ['required', 'trim'],
              ['validatePropertyReference', excludedPropertyReference]
            ]"
            outer-class="tw-m-0"
          />

          <FormulateInput
            type="select"
            name="transaction_type"
            label="Transactietype"
            :options="TRANSACTION_TYPES"
            :disabled="values.has_transaction"
            validation="required"
            outer-class="tw-m-0"
          />

          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            name="office"
            label="Kantoor"
            placeholder="Selecteer kantoor"
            validation="required"
            outer-class="tw-m-0"
            @input="updateOffice"
          />

          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="collaborator"
            label="Makelaar"
            placeholder="Selecteer makelaar"
            validation="required"
            outer-class="tw-m-0 tw-col-span-2"
            @input="updateCollaborator"
          />

          <FormulateInput
            id="concept_type"
            type="select"
            name="concept_type"
            label="Concept"
            :options="CONCEPT_OPTIONS"
            validation="required"
            outer-class="tw-m-0"
          />
        </div>
        <div>
          <FormulateInput
            id="allow_automated_communication"
            type="checkbox"
            name="allow_automated_communication"
            label="Automatische communicatie aan eigenaar toestaan"
          />
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Adres</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="street"
            name="street"
            label="Straat"
            placeholder="Zoek een straat"
            validation="required"
            outer-class="tw-m-0"
            @callback="streetSelected"
          />
          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="text"
              name="number"
              label="Huisnummer"
              placeholder="Huisnummer"
              validation="required:trim"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="text"
              name="box"
              label="Bus"
              placeholder="Bus"
              outer-class="tw-m-0"
            />
          </div>
          <FormulateInput
            type="autocomplete"
            auto-complete-type="city"
            name="city"
            label="Plaats"
            placeholder="Zoek op postcode of plaatsnaam"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="button"
            title="Geografisch"
            :input-class="['tw-bg-transparent tw-text-success tw-border tw-border-success tw-w-full hover:tw-bg-success hover:tw-text-white']"
            outer-class="tw-m-0"
            @click="showMapModal"
          >
            <i class="fa fa-globe" /> Geografisch
          </FormulateInput>
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Pandgegevens</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="select"
            name="type"
            label="Type"
            :options="PROPERTY_TYPES"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="build_year"
            label="Bouwjaar"
            placeholder="Bouwjaar"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="renovation_year"
            label="Renovatiejaar"
            outer-class="tw-m-0"
            placeholder="Renovatiejaar"
          />
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Eigenaars</h2>
        <EntityContactsFormPartial
          ref="entityOwners"
          contact-role="owner"
          :form-values="values"
          :property-id="propertyId"
          :show-disclaimer="false"
        />
      </div>

      <div class="tw-my-8">
        <h2>Prijs</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <div v-if="isSales" class="tw-col-span-3 tw-grid md:tw-grid-cols-3 tw-gap-4">
            <FormulateInput
              v-model="isVatSystem"
              type="checkbox"
              name="is_vat_system"
              label="Btw-stelsel"
              outer-class="tw-m-0"
            />
            <FormulateInput
              v-if="isVatSystem"
              type="radio"
              name="vat_rate"
              label="BTW-type:"
              value="21"
              :options="{ 6: '6%', 12: '12%', 21: '21%' }"
              :input-class="['tw-my-0']"
              :wrapper-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
              :element-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
              outer-class="tw-m-0"
            />
          </div>
          <FormulateInput
            v-show="splitPriceInputs"
            type="number"
            id="price_land_share"
            name="price_land_share"
            label="Prijs grondaandeel"
            placeholder="Prijs grondaandeel"
            :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
            step=".01"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
          <FormulateInput
            v-show="splitPriceInputs"
            type="number"
            id="price_infrastructure"
            name="price_infrastructure"
            label="Prijs constructiewaarde"
            placeholder="Prijs constructiewaarde"
            :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
            step=".01"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
          <FormulateInput
            v-show="!splitPriceInputs"
            id="price"
            type="number"
            name="price"
            :label="isSales ? 'Aankoopbedrag' : 'Huurprijs (maandhuur)'"
            :placeholder="isSales ? 'Aankoopbedrag' : 'Huurprijs (maandhuur)'"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
        </div>
      </div>

      <div>
        <h2>Marketing</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-1 tw-gap-x-4">
          <FormulateInput
            type="checkbox"
            name="is_sneak_peek"
            label="Sneak peek"
            outer-class="tw-m-0"
            :disabled="sneakPeekIsDisabled"
           >
            <template #label>
              <label for="is_sneak_peek" class="formulate-label">
                Sneak peek
                <Tooltip class="tw-inline-block">
                  <i class="fas fa-info-circle" />
                  <template #popper>
                    <div class="tw-text-center">
                      Met een sneak peek trekken we al potentiële kopers aan voordat het dossier volledig gepubliceerd is. Deze teaser komt op de website en op Facebook- en Instagram-stories met een wazige foto.
                    </div>
                  </template>
                </Tooltip>
              </label>
            </template>
          </FormulateInput>
          <FormulateInput
            v-if="values.is_sneak_peek"
            type="file"
            name="sneak_peek_picture"
            label="Sneak peek foto"
            :uploader="uploadPicture"
            outer-class="formulate-input tw-my-0"
            wrapper-class="formulate-input-wrapper"
            :upload-area-mask-class="['tw-h-10 tw-mt-1']"
            :file-class="['tw-h-10 !tw-mt-1']"
            element-class="formulate-input-element formulate-input-element--file"
          >
            <template #file="context">
              <BaseFileUploadDisplay v-bind="context" />
            </template>
          </FormulateInput>
        </div>
      </div>
      <div class="tw-flex tw-justify-end">
        <div class="tw-text-right">
          <FormulateErrors />
          <FormulateInput
            type="submit"
            title="Opslaan"
            :disabled="isLoading"
            outer-class="tw-my-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
              ]"
            />
            Naar stap 2
          </FormulateInput>
        </div>
      </div>
    </FormulateForm>
</template>

<script>
import { currency, percentage, today } from '@/utils/helpers'
import { questionModal, errorModal } from '@/modalMessages'

import EntityContactsFormPartial from '@/components/properties/EntityContactsFormPartial'

import { CONCEPT_OPTIONS } from '@/forms/selectOptions'
import { PROPERTY_TYPES } from '@/forms/schemas/portfolio/property/address'

import { markDeal } from '@/services/sales'
import {
  createDefaultPropertyFolderStructure,
  createPropertyFile,
  getPropertyPictures,
  updateProperty,
  updatePropertyCollaborators,
  updatePropertyConcept,
  updatePropertyFinancial,
  updatePropertyOffices,
  updatePropertyTechnical,
  uploadFile,
  uploadPropertyPicture,
  getPropertyGeolocation
} from '@/services/properties'
import { Tooltip } from 'floating-vue'

const FILE_TYPES = {
  2: 53, // If transaction_type is 'Te koop'
  3: 52, // If transaction_type is 'Te huur'
  4: 76 // If transaction_type is 'Over te nemen'
}

export default {
  name: 'WizardDossieropbouwStep1',
  components: {
    EntityContactsFormPartial,
    Tooltip
  },
  props: {
    initialValues: {
      type: Object,
      required: true
    },
    propertyId: {
      type: [String, Number],
      required: true
    }
  },
  constants: {
    PROPERTY_TYPES,
    CONCEPT_OPTIONS,
    TRANSACTION_TYPES: {
      2: 'Te koop',
      3: 'Te huur',
      4: 'Over te nemen'
    },
    CURRENT_STEP: 1
  },
  data () {
    return {
      values: {},
      formKey: 'init',
      isVatSystem: false,
      transactionType: null,
      excludedPropertyReference: null, // We store the property's existing reference for the valid reference check
      currentOffice: null,
      currentCollaborator: null,
      defaultFolderStructureCreated: false,
      pictureIdForSneakPeek: null,
      shouldCreateTransaction: false,
      geolocation: null
    }
  },
  computed: {
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    },
    isSales () {
      return [2, 4].includes(parseInt(this.values.transaction_type))
    },
    splitPriceInputs () {
      return this.isSales && this.isVatSystem
    },
    sneakPeekIsDisabled () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod' // Disable sneak peek in production for now
    }
  },
  created () {
    this.init()
  },
  methods: {
    currency,
    percentage,
    init () {
      this.loadDossierData()
      this.getGeolocation()
    },
    async showMapModal () {
      const response = await this.updateAddress(this.values)
      this.$emit('showMapModal')
      return response
    },
    hide () {
      this.$refs.modal.hide()
    },
    streetSelected (street) {
      if (street?.city) this.values.city = street.city
      if (street?.number) this.values.number = street.number
    },
    parseFileObj (file) {
      if (!file) return false
      // Formulate input requires filename and url
      const { id, filename, url } = file
      return [{ id, name: filename, url }]
    },
    parsePictureObj (picture) {
      if (!picture) return false
      const { id, url } = picture
      return [{ id, url }]
    },
    loadDossierData () {
      this.formKey = Math.random()
      const {
        reference,
        type,
        street,
        office,
        collaborator,
        mandate_file,
        mandate_start_date,
        transaction_type,
        commission_fix_in,
        should_create_transaction,
        lead_to_set_as_deal,
        ...values
      } = this.initialValues
      this.values = {
        office,
        reference,
        collaborator,
        type: type.id,
        street: { text_nl: street },
        mandate_file: this.parseFileObj(mandate_file),
        mandate_start_date: mandate_start_date || today.date,
        transaction_type: transaction_type || 2, // Te koop
        ...values
      }
      this.excludedPropertyReference = reference
      this.currentOffice = office?.id
      this.currentCollaborator = collaborator?.id
      this.shouldCreateTransaction = should_create_transaction
      this.leadToSetAsDeal = lead_to_set_as_deal
    },
    async createDefaultFolderStructure () {
      // Only create the folder structure if the transaction type is "for sale"
      if (!this.defaultFolderStructureCreated && this.values.transaction_type === 2) {
        const response = await createDefaultPropertyFolderStructure(this.propertyId)
        this.defaultFolderStructureCreated = true
        return response.data
      }
      return null
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folders = await this.createDefaultFolderStructure()
        // Look for a folder called "Verkoop- verhuuropdracht"
        const folder_id = folders?.find(folder => folder.name === 'Verkoop- verhuuropdracht')?.id
        const payload = { key, filename, file_type: FILE_TYPES[this.values.transaction_type], folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        return this.parseFileObj(response.data)
      } catch (error) {
        this.$formulate.handle(error, 'dossieropbouwForm')
      }
    },
    async uploadPicture (picture, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', picture)
        progress(50)
        const response = await uploadPropertyPicture(this.propertyId, formData)
        this.pictureIdForSneakPeek = response.data.id
        progress(100)
        return this.parsePictureObj(response.data)
      } catch (error) {
        this.$formulate.handle(error, 'dossieropbouwForm')
      }
    },
    async updateProperty ({
      reference,
      street,
      number,
      box,
      city,
      type,
      parcels,
      transaction_type,
      mandate_start_date,
      mandate_signature_date,
      allow_automated_communication
    }) {
      const payload = {
        reference,
        number,
        box,
        type,
        parcels,
        transaction_type,
        mandate_start_date,
        mandate_signature_date,
        city: city.id,
        street: street.text_nl,
        dossier_preparation_wizard: true,
        allow_automated_communication
      }
      const response = await updateProperty(this.propertyId, payload)
      return response.data
    },
    async updateAddress ({ street, number, box, city }) {
      const payload = {
        box,
        number,
        city: city.id,
        street: street.text_nl
      }
      const response = await updateProperty(this.propertyId, payload)
      return response
    },
    async updatePropertyFinancial ({ is_vat_system, price, price_land_share, price_infrastructure, transaction_type }) {
      const payload = { is_vat_system, price, price_land_share, price_infrastructure, transaction_type }
      const response = await updatePropertyFinancial(this.propertyId, payload)
      return response.data
    },
    async updatePropertyConcept ({ concept_type }) {
      const payload = { type: concept_type }
      const response = await updatePropertyConcept(this.propertyId, payload)
      return response.data
    },
    async updatePropertyTechnical ({ build_year, renovation_year }) {
      const payload = { build_year, renovation_year }
      const response = await updatePropertyTechnical(this.propertyId, payload)
      return response.data
    },
    async markDeal ({ lead_to_set_as_deal }) {
      if (!lead_to_set_as_deal) return // No lead, no need
      const response = await markDeal(lead_to_set_as_deal.id)
      return response.data
    },
    async updateCollaborator (collaborator) {
      if (!collaborator?.id || this.currentCollaborator === collaborator.id) return false

      const response = await updatePropertyCollaborators(
        this.propertyId,
        {
          collaborator_id_list: [collaborator.id]
        }
      )
      this.currentCollaborator = collaborator.id
      return response
    },
    async updateOffice (office) {
      if (!office?.id || this.currentOffice === office.id) return false

      const response = await updatePropertyOffices(
        this.propertyId,
        {
          office_id_list: [office.id]
        }
      )
      this.currentOffice = office.id
      return response
    },
    async triggerSubmission (data) {
      // const owners = await this.$refs.entityOwners.submit()
      // if (!owners) return // Stop form submission if the API call fails
      try {
        const owners = await this.$refs.entityOwners.submit()
        if (!owners) return // Stop form submission if the API call fails
        const [financial, concept] = await Promise.all([
          this.updatePropertyFinancial(data),
          this.updatePropertyConcept(data),
          this.updatePropertyTechnical(data),
          this.markDeal(data),
          this.createDefaultFolderStructure()
        ])
        const property = await this.updateProperty(data)
        return { owners, property, financial, concept }
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'dossieropbouwForm')
      }
    },
    async submit (data) {
      // First update the owners as this is an API call that is expected to trigger BE validations (duplicate contacts to be merged)
      if (!data.build_year) {
        const result = await questionModal(
          'Let op: bouwjaar is niet ingevuld! Dit is een belangrijk veld voor de opbouw van jouw dossier. Weet je zeker dat je wil verder gaan?', 'Bouwjaar'
        )
        if (!result.value) return
      }
      const picturesPayload = { propertyId: this.propertyId }
      const pictureCount = (await getPropertyPictures(picturesPayload)).data.count
      if (data.is_sneak_peek && pictureCount === 0 && !this.pictureIdForSneakPeek) {
        errorModal(
          'Om gebruik te kunnen maken van sneak peek moet er minstens één foto zijn voor dit pand of moet je een sneak peek foto uploaden.', 'Sneak peek'
        )
      }
      await this.getGeolocation()
      if (this.geolocation.latitude === 0 && this.geolocation.longitude === 0) {
        errorModal(
          'Het adres van deze woning kon niet worden gevonden. Stel de geolocatie handmatig in via de "Geografisch" knop.', 'Geografisch'
        )
        return
      }
      data.sneak_peak_picture_id = this.pictureIdForSneakPeek
      data.should_create_transaction = this.shouldCreateTransaction
      data.lead_to_set_as_deal = this.leadToSetAsDeal
      const response = await this.triggerSubmission(data)
      this.$emit('finished', data, this.CURRENT_STEP)
      return response
    },
    async getGeolocation () {
      const response = await getPropertyGeolocation(this.propertyId)
      this.geolocation = response.data
    }
  }
}
</script>
