var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-grid md:tw-grid-cols-3 md:tw-divide-x tw-gap-8 tw-rounded-md tw-shadow-card tw-p-4"},[_c('div',{on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)}}},[_c('FormulateInput',{attrs:{"type":"group","label":_vm.labels.contactsInputLabel,"repeatable":true,"remove-position":"after","validation":_vm.contactValidations,"group-repeatable-class":"tw-relative","outer-class":"tw-m-0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var index = ref.index;
return [_c('div',{staticClass:"tw-cursor-move tw-my-1",attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.onDragStart($event, {
            index: index,
            source: ("contact" + index),
            contact: model[index],
          })}}},[_c('FormulateInput',{attrs:{"name":_vm.contactRole,"type":"autocomplete","auto-complete-type":"contact","placeholder":"Selecteer een contact","validation":"required","validation-messages":{
              required: 'Contact is verplicht.'
            },"hide-clear-button":true,"input-class":['tw-rounded-l-none'],"element-class":['tw-flex tw-items-center'],"outer-class":"tw-m-0 tw-w-full"},on:{"input":function($event){return _vm.handleGoogleReview($event, model[index])}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
            var model = ref.model;
return [_c('div',{staticClass:"tw-flex tw-justify-end"},[(_vm.shouldShowCreateButton(model))?_c('button',{staticClass:"link tw-font-semibold",attrs:{"type":"button","title":_vm.labels.addContact},on:{"click":_vm.showContactCreateModal}},[_c('i',{staticClass:"far fa-user-plus"})]):_c('router-link',{attrs:{"to":{ name: 'ContactDetails', params: { id: model.id } },"target":"_blank"}},[_c('i',{staticClass:"far fa-info-circle"})])],1)]}},{key:"help",fn:function(ref){
            var model = ref.model;
return [(model.id && _vm.askGoogleReview)?_c('FormulateInput',{attrs:{"type":"checkbox","name":"google_review_contact","label":"Google review opvragen","value":model.id,"outer-class":"tw-my-1"}}):_vm._e()]}}],null,true)},[_c('div',{staticClass:"input-unit tw-text-base tw-rounded-l tw-rounded-r-none tw-border-l tw-border-r-0",attrs:{"slot":"prefix"},slot:"prefix"},[_c('i',{staticClass:"fas fa-arrows"})])])],1)]}},{key:"addmore",fn:function(ref){
            var addMore = ref.addMore;
return [_c('button',{staticClass:"tw-my-4 tw-font-semibold link",attrs:{"type":"button"},on:{"click":addMore}},[_vm._v(" "+_vm._s(_vm.labels.addContact)+" ")])]}},{key:"remove",fn:function(ref){
            var model = ref.model;
            var index = ref.index;
            var removeItem = ref.removeItem;
return [_c('button',{class:[
            'tw-absolute tw-right-0',
            'tw-px-3 tw-text-danger',
            'hover:tw-text-darken-danger',
            'disabled:tw-text-gray-cc disabled:tw-cursor-not-allowed',
            model[index] && model[index][_vm.contactRole]
            && model[index][_vm.contactRole].id && _vm.askGoogleReview
              ? 'tw-inset-y-2'
              : 'tw-inset-y-1/2'
          ],attrs:{"type":"button","title":"Verwijderen"},on:{"click":function($event){return _vm.removeContact(index, removeItem, model[index])}}},[_c('i',{staticClass:"fas fa-trash"})])]}}]),model:{value:(_vm.contacts),callback:function ($$v) {_vm.contacts=$$v},expression:"contacts"}}),_c('ContactCreateModal',{ref:"contactCreateModal",attrs:{"redirect":false},on:{"success":_vm.newContactCreated}}),(_vm.contactsWithoutEmailAddress)?_c('div',[_c('i',{staticClass:"fa fa-exclamation-triangle tw-text-xs tw-text-darken-warning tw-mr-0.5"}),_vm._v(" Contact zonder e-mailadres ("+_vm._s(_vm.contactsWithoutEmailAddress)+") ")]):_vm._e()],1),_c('EntityInvoiceContacts',{ref:"invoiceContacts",staticClass:"tw-col-span-2",attrs:{"form-values":_vm.formValues,"property-id":_vm.propertyId,"project-id":_vm.projectId,"contacts":_vm.contacts,"contact-role":_vm.contactRole,"show-disclaimer":_vm.showDisclaimer}}),_c('ContactMergeModal',{ref:"mergeModal",attrs:{"automatic-pop-up":true,"header-text":"Er zijn dubbele eigenaars gevonden op basis van e-mailadres. Voeg deze samen of klik onderaan op 'Deze stap overslaan' om verder te gaan zonder samen te voegen."},on:{"contactsMergeCancelled":_vm.handleContactsMergeCancelled}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }